<template>
  <div :class="$style.root">
    <h1 v-if="$route.meta.title" class="mb-4">{{$route.meta.title}}</h1>
    <div class="mb-4 clearfix">
      <VBtn class="float-right ml-2" depressed color="success" @click="onCreate">
        Новое согласование
      </VBtn>
      <VBtn depressed @click="toHome">
        <VIcon v-text="'mdi-arrow-left'" />
        На главную
      </VBtn>
    </div>
    <AcceptanceList
        :headers="[
          { text: 'Номер', value: 'id', sortable: false },
          { text: 'Дата изменения', value: 'updated', sortable: false },
          { text: 'Номер договора', value: 'contract', sortable: false },
          { text: 'Номер доп. соглашения', value: 'agreement', sortable: false },
          { text: 'Вид работ', value: 'work', options: works, sortable: false },
          { text: 'Цех', value: 'place', options: places, sortable: false },
          { text: 'ФИО инициатора', value: 'initiator', sortable: false },
          { text: 'Статус', value: 'status', options: statuses, sortable: false },
        ]"
        :items="acceptances.items"
        :count="acceptances.count"
        :page="page"
        :size="size"
        :loading="loading"
    />
  </div>
</template>

<script>
import { debounce, values } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import AcceptanceList from '@/components/actsvr/AcceptanceList/AcceptanceList';

import { STATUSES, WORKS, PLACES } from '@/store/actsvr/enums';
export default {
  name: 'AcceptanceListView',
  components: {
    AcceptanceList,
  },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
  computed: {
    ...mapGetters({
      loading: 'actsvr/pending',
      acceptances: 'actsvr/acceptances',
    }),
    works() {
      return values(WORKS);
    },
    places() {
      return values(PLACES);
    },
    statuses() {
      return values(STATUSES);
    },
  },
  methods: {
    ...mapActions({
      fetchAcceptanceList: 'actsvr/fetchAcceptanceList',
    }),
    onCreate() {
      this.$router.push({ name: 'actsvr/AcceptanceCreateView'})
    },
    onUpdateState: debounce(function() {
      const { page, size, filter } = this;
      this.fetchAcceptanceList({ page, size, filter });
    }, 500),
    toHome() {
      window.location = '/';
    },
  },
  watch: {
    page: function() {
      this.onUpdateState();
    },
    size: function() {
      this.onUpdateState();
    },
    filter: {
      deep: true,
      handler: function() {
        this.onUpdateState();
      }
    }
  },
  mounted() {
    this.onUpdateState();
  }
}
</script>

<style module lang="scss">
.root {}
</style>
